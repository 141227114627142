import { HomeIcon, SettingsIcon, BlogIcon, FolderIcon, MenuHorizontalIcon, NotificationIcon, SmileyJoyIcon, GiftCardFilledIcon, DeliveryFilledIcon, ProductIcon, ChartVerticalFilledIcon, PersonIcon, IncomingIcon, ProductReferenceIcon, PackageIcon, CartDownIcon, GamesIcon, CursorBannerIcon, ShieldCheckMarkIcon, AtmWithdrawalIcon, TargetIcon, ConnectIcon, CashDollarIcon, StatusActiveIcon, StoreManagedIcon, PersonalizedTextIcon, AffiliateIcon, LockFilledIcon, MetaobjectIcon, EmailIcon, EmailFollowUpIcon, EmailNewsletterIcon, StarFilledIcon, WalletIcon, AppsIcon, CollectionFeaturedIcon, StarIcon } from '@shopify/polaris-icons';
import { Navigation, Text, BlockStack, Badge } from '@shopify/polaris';
import { useLocation } from 'react-router-dom';
import { default as packageInformation } from '../../package.json';
import helpers from 'helpers/index';
import Capacity from 'components/Capacity';
const generalMenu: any[] = [{
  url: '/',
  label: 'Trang chủ',
  icon: HomeIcon,
  exactMatch: true,
  subNavigationItems: [{
    url: '/social',
    label: 'Social'
  }, {
    url: '/profile',
    label: 'Trang cá nhân'
  }, {
    url: '/my-wallet',
    label: 'Ví của tôi'
  }, {
    url: '/my_referrer',
    label: 'Giới thiệu kiếm tiền'
  }]
}];

/**
 * Public, no login
 */
const saleMenus: any[] = [{
  url: '/customer',
  label: 'Khách hàng',
  icon: PersonIcon
}, {
  url: '/shopping_cart',
  label: 'Giỏ hàng',
  icon: CartDownIcon
}, {
  url: '/wishlist',
  label: 'Wishlist',
  icon: StatusActiveIcon
}, {
  url: '/order',
  label: 'Đơn hàng',
  icon: ProductIcon
}];
const stockMenus: any[] = [{
  url: '/product',
  label: 'Sản phẩm',
  icon: ProductReferenceIcon,
  subNavigationItems: [{
    url: '/product',
    label: 'Tất cả',
    icon: MenuHorizontalIcon
  }, {
    url: '/product_category',
    label: 'Danh mục',
    icon: MenuHorizontalIcon
  }, {
    url: '/product_collection',
    label: 'Bộ sưu tập',
    icon: MenuHorizontalIcon
  }, {
    url: '/product_brand',
    label: 'Nhãn hiệu',
    icon: MenuHorizontalIcon
  }]
}, {
  url: '/stock',
  label: 'Kho hàng',
  icon: StoreManagedIcon
}, {
  url: '/logistic',
  label: 'Vận chuyển',
  icon: DeliveryFilledIcon
}, {
  url: '/voucher',
  label: 'Khuyến mại',
  icon: CollectionFeaturedIcon
}, {
  url: '/gift',
  label: 'Đổi quà(redeem)',
  icon: GiftCardFilledIcon,
  subNavigationItems: [{
    url: '/wallet_code',
    label: 'Wallet code',
    icon: MenuHorizontalIcon
  }, {
    url: '/gift',
    label: 'Quà',
    icon: MenuHorizontalIcon
  }, {
    url: '/redeem_request',
    label: 'Yêu cầu đổi quà',
    icon: MenuHorizontalIcon
  }]
}];
const supporterMenus: any[] = [{
  url: '/contactform',
  label: 'Contact Form',
  icon: PersonalizedTextIcon
}, {
  url: '/transactions',
  label: 'Giao dịch',
  icon: CashDollarIcon
}, {
  url: '/affiliate',
  label: 'Affiliate',
  icon: AffiliateIcon
}];
const marketingMenus: any[] = [{
  url: '/activity',
  label: 'Hoạt động',
  icon: ConnectIcon
}, {
  url: '/user_activity',
  label: 'Tương tác',
  icon: TargetIcon,
  subNavigationItems: [{
    url: '/user_activity',
    label: 'Các tương tác',
    icon: MenuHorizontalIcon
  }, {
    url: '/activity_point',
    label: 'Tương tác & Điểm',
    icon: MenuHorizontalIcon
  }]
}];
const advanceUserItems = [{
  url: '/banner',
  label: 'Banner',
  icon: CursorBannerIcon
}, {
  url: '/comment',
  label: 'Comments',
  icon: SmileyJoyIcon
}, {
  url: '/review',
  label: 'Review',
  icon: StarIcon
}, {
  url: '/notification',
  label: 'Notification',
  icon: NotificationIcon
}, {
  url: '/webmail',
  label: 'Webmail',
  icon: EmailIcon,
  subNavigationItems: [{
    url: '/webmail',
    icon: EmailFollowUpIcon,
    label: 'Sent'
  }, {
    url: '/webmail/incoming',
    icon: EmailNewsletterIcon,
    label: 'Incoming'
  }]
}, {
  url: '/posts',
  label: 'Bài đăng và trang',
  icon: BlogIcon,
  subNavigationItems: [{
    url: '/posts',
    label: 'Danh sách bài đăng'
  }, {
    url: '/posts/category',
    label: 'Chuyên mục'
  }]
}, {
  url: '/media',
  label: 'Media',
  icon: FolderIcon
}];
const gamificationMenu = [{
  url: '/game',
  label: 'Quản lý game',
  icon: GamesIcon,
  badge: <Badge tone="critical">hot</Badge>,
  subNavigationItems: [{
    url: '/game/checkin',
    label: 'Điểm danh'
  }, {
    url: '/game/lucky_wheel',
    label: 'Vòng quay may mắn'
  }]
}, {
  url: '/achievement',
  label: 'Thành tích',
  icon: StarFilledIcon
}, {
  url: '/rank',
  label: 'Xếp hạng',
  icon: MetaobjectIcon,
  subNavigationItems: [{
    url: '/rank',
    label: 'Các hạng'
  }]
}];

/**
 * For admin
 */
const adminMenuItems = [{
  url: '/analytics',
  label: 'Báo cáo',
  icon: ChartVerticalFilledIcon,
  subNavigationItems: [{
    url: '/analytics/customer',
    label: 'Báo cáo khách hàng'
  }, {
    url: '/analytics/order',
    label: 'Báo cáo đơn hàng'
  }, {
    url: '/analytics/product',
    label: 'Báo cáo sản phẩm'
  }, {
    url: '/analytics/staff',
    label: 'Hiệu năng nhân viên'
  }]
}, {
  url: '/incoming_webhook',
  label: 'Incoming Webhook',
  icon: IncomingIcon
}, {
  url: '/outgoing_webhook',
  label: 'Outgoing Webhook',
  icon: PackageIcon
}, {
  url: '/settings/',
  label: 'Cài đặt chung',
  icon: SettingsIcon,
  subNavigationItems: [{
    url: '/settings',
    icon: MenuHorizontalIcon,
    label: 'System'
  }, {
    url: '/backup',
    icon: MenuHorizontalIcon,
    label: 'Back up'
  }, {
    url: '/embed',
    icon: MenuHorizontalIcon,
    label: 'Mã nhúng & kết nối'
  }, {
    url: '/order_setting',
    icon: MenuHorizontalIcon,
    label: 'Đơn hàng'
  }, {
    url: '/review_setting',
    icon: MenuHorizontalIcon,
    label: 'Review'
  }, {
    url: '/keymap',
    icon: MenuHorizontalIcon,
    label: 'Keymap'
  }]
}, {
  url: '/affiliate/setup',
  label: 'Cài đặt Affiliate',
  icon: AffiliateIcon,
  subNavigationItems: [{
    url: '/affiliate/setup',
    label: 'Cài đặt chung'
  }, {
    url: '/affiliate/default_setup',
    label: 'Cài đặt mặc định'
  }]
}, {
  url: '/app',
  label: 'SSO',
  icon: AppsIcon
}, {
  url: '/email_setting/',
  label: 'Cài đặt Email',
  icon: EmailIcon,
  subNavigationItems: [{
    url: '/email_setting',
    icon: MenuHorizontalIcon,
    label: 'Tài khoản'
  }, {
    url: '/email_template',
    icon: MenuHorizontalIcon,
    label: 'Biểu mẫu'
  }]
}, {
  url: '/security/',
  label: 'Bảo mật',
  icon: LockFilledIcon
}, {
  url: '/wallet/',
  label: 'Cài đặt ví',
  icon: WalletIcon
}, {
  url: '/users/',
  label: 'Người dùng',
  icon: PersonIcon,
  subNavigationItems: [{
    url: '/users',
    icon: MenuHorizontalIcon,
    label: 'Tất cả tài khoản'
  }, {
    url: '/user_group',
    icon: MenuHorizontalIcon,
    label: 'Nhóm người dùng'
  }]
}, {
  url: '/user_verified',
  label: 'Yêu cầu chứng thực',
  icon: ShieldCheckMarkIcon,
  badge: <Badge tone="magic">---</Badge>
}, {
  url: '/withdraw',
  label: 'Yêu cầu rút tiền',
  icon: AtmWithdrawalIcon,
  badge: <Badge tone="magic">---</Badge>
}];

/**
 * START ...
 * @returns
 */
export default function Sidebar() {
  const location = useLocation();
  const version = process.env.REACT_APP_VERSION;
  const polarisVersion = packageInformation['dependencies']['@shopify/polaris'] || '';
  return <Navigation location={location.pathname}>
      <Navigation.Section items={generalMenu} />

      <Capacity current_user_can="sale">
        <Navigation.Section title="Dành cho Sale" separator items={saleMenus} />

        <Navigation.Section title="Quản lý kho hàng" separator items={stockMenus} />

        <Navigation.Section title="Dành cho Supporter" separator items={supporterMenus} />
        <Navigation.Section title="Dành cho Marketing" separator items={marketingMenus} />
      </Capacity>
      <Capacity current_user_can="sale_admin">
        <Navigation.Section title="Nâng cao" separator items={advanceUserItems} />
      </Capacity>

      <Capacity current_user_can="admin">
        <Navigation.Section title="Gamification" separator items={gamificationMenu} />
        <Navigation.Section title="Quản trị CRM" separator items={adminMenuItems} />
      </Capacity>

      <Navigation.Section fill items={[]} />

      <BlockStack align="center" inlineAlign="center" gap={'200'}>
        <Text as="p" variant="bodyXs" tone="subdued">
          NBEE CRM &copy;2021, version {version}
          <br />
          build: {packageInformation.build_version}
          <br />
          {helpers.isDev() && `Polaris Framework ver ${polarisVersion}`}
        </Text>
        <div style={{
        marginBottom: 5,
        marginTop: 5
      }}></div>
      </BlockStack>
    </Navigation>;
}