import { compiledBlock as _compiledBlock } from "million/react";
const ActivityPoint_1 = _compiledBlock(_props => <slot>
      {_props.v0}
      {_props.v1}

      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </slot>, {
  name: "ActivityPoint_1",
  portals: ["v0", "v1"]
});
const ActivityPoint = function ActivityPoint() {
  const [order_complete_point, setOrder_complete_point] = useState(0);
  const [order_creator_complete_point, setOrder_creator_complete_point] = useState(0);
  const [referrer_added_point, setReferrer_added_point] = useState(0);
  const [referrer_removed_point, setReferrer_removed_point] = useState(0);
  const [customer_added_point, setCustomer_added_point] = useState(0);
  const [customer_removed_point, setCustomer_removed_point] = useState(0);
  const [review_added_point, setReview_added_point] = useState(0);
  return /*@million jsx-skip*/<ActivityPoint_1 v0={<Helmet>
        <title>Cài đặt tương tác và điểm</title>
      </Helmet>} v1={<Page narrowWidth title="Tương tác và điểm" subtitle="Mỗi hành động sau đây tương ứng với một số điểm nhất định">
        <Form onSubmit={() => {}}>
          <BlockStack gap="400">
            <Card>
              <Text as="h3" variant="headingMd">
                Đơn hàng hoàn thành (Dành cho người mua)
              </Text>
              <Text as="p" variant="bodyXs" tone="subdued">
                Đơn hàng được đánh dấu là hoàn thành sau khi đơn hàng được giao hàng thành công và đối soát.
              </Text>
              <br />
              <TextField label="Điểm" autoComplete="off" requiredIndicator value={__helpers.formatNumber(order_complete_point)} onChange={e => setOrder_complete_point(__helpers.parseNumeric(e))} />

              <br />

              <Text as="h3" variant="headingMd">
                Đơn hàng hoàn thành (Dành cho người lên đơn)
              </Text>
              <Text as="p" variant="bodyXs" tone="subdued">
                Đơn hàng được đánh dấu là hoàn thành sau khi đơn hàng được giao hàng thành công và đối soát.
              </Text>
              <br />
              <TextField label="Điểm" autoComplete="off" requiredIndicator value={__helpers.formatNumber(order_creator_complete_point)} onChange={e => setOrder_creator_complete_point(__helpers.parseNumeric(e))} />
            </Card>

            <Card>
              <Text as="h3" variant="headingMd">
                Mời thêm một khách hàng (referrer)
              </Text>
              <Text as="p" variant="bodyXs" tone="subdued">
                Khi mời được một thành viên vào hệ thống, bạn sẽ nhận được điểm.
              </Text>
              <br />
              <TextField label="Điểm" autoComplete="off" requiredIndicator value={__helpers.formatNumber(referrer_added_point)} onChange={e => setReferrer_added_point(__helpers.parseNumeric(e))} />
              <br />
              <Text as="h3" variant="headingMd">
                Khi khách mời bị deactive (khóa tài khoản)
              </Text>
              <Text as="p" variant="bodyXs" tone="subdued">
                Khi mời được một thành viên vào hệ thống, sau đó người này bị deactive (khóa tài khoản), bạn sẽ bị trừ điểm.
              </Text>
              <br />
              <TextField label="Điểm trừ" autoComplete="off" requiredIndicator value={__helpers.formatNumber(referrer_removed_point)} onChange={e => setReferrer_removed_point(__helpers.parseNumeric(e))} />
            </Card>

            <Card>
              <Text as="h3" variant="headingMd">
                Thêm một khách hàng vào hệ thống (dành cho nhân viên)
              </Text>
              <Text as="p" variant="bodyXs" tone="subdued">
                Khách hàng được thêm vào hệ thống thông qua admin.
              </Text>
              <br />
              <TextField label="Điểm" autoComplete="off" requiredIndicator value={__helpers.formatNumber(customer_added_point)} onChange={e => setCustomer_added_point(__helpers.parseNumeric(e))} />

              <br />

              <Text as="h3" variant="headingMd">
                Khi khách hàng bị xóa
              </Text>
              <Text as="p" variant="bodyXs" tone="subdued">
                Do trùng lặp, do khách hàng rác, và sale admin xóa khách hàng đi.
              </Text>
              <br />
              <TextField label="Điểm trừ" autoComplete="off" requiredIndicator value={__helpers.formatNumber(customer_removed_point)} onChange={e => setCustomer_removed_point(__helpers.parseNumeric(e))} />
            </Card>

            <Card>
              <Text as="h3" variant="headingMd">
                Thêm một review
              </Text>
              <Text as="p" variant="bodyXs" tone="subdued">
                Khi khách hàng review và review đó được duyệt.
              </Text>
              <br />
              <TextField label="Điểm" autoComplete="off" requiredIndicator value={__helpers.formatNumber(review_added_point)} onChange={e => setReview_added_point(__helpers.parseNumeric(e))} />
            </Card>
          </BlockStack>
        </Form>
      </Page>} />;
};
import { block as _block } from "million/react";
import { BlockStack, Card, Form, Text, Page, TextField } from '@shopify/polaris';
import __helpers from 'helpers/index';
import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
export default ActivityPoint;